<template>
    <div>
        <a-modal
                :footer="null"
                width="580px"
                :dialog-style="{ top: '150px',borderRadius:'30px',padding:0}"
                :visible="qrDownShow"
                :maskClosable="false"
                @cancel="() => setModal1Visible(false)"
        >
            <!--            <div class="pay_home">-->
            <div :class="{ 'pay_home': true, 'pageHomeColor1': chargeType === 2, 'pageHomeColor2': chargeType === 1 }">
                <div :class="{ 'pay2_title': true, 'select1': chargeType === 2, 'select2': chargeType === 1 }">
                    <div class="choseBuy" @click="chosePayType(2)">
                        <div style="font-family: 'Heiti'">直接购买下载</div>
                        <!--                        <div class="pay_not_recommend" v-show="chargeType === 2">当前无折扣-->
                        <!--                            <a-icon type="account-book" /></div>-->
                    </div>

                    <div class="choseCharge" @click="chosePayType(1)">
                        <div v-show="chargeType === 2" class="discount">
                            <a-icon type="thunderbolt" theme="twoTone" two-tone-color="#FFEA35FF"/>
                            折扣
                            <a-icon type="thunderbolt" theme="twoTone" two-tone-color="#FFEA35FF"/>
                        </div>
                        <div class="pay_recommend" v-show="chargeType === 2">强力推荐
                            <a-icon type="like" theme="twoTone" two-tone-color="#eb2f96"/>
                        </div>
                        <div class="pay_recommend_2" v-show="chargeType === 1">推荐
                            <a-icon type="like" theme="twoTone" two-tone-color="#eb2f96"/>
                        </div>
                        <div style="font-family: 'Heiti TC'" class="pay2_chargePromote">充值优惠</div>
                    </div>
                </div>

                <div class="pay2_content" v-show="chargeType===1">
                    <div class="pay_titles">
                        优惠充值
                        <div class="pay_tips_active">
                            <img src="../../assets/image/img/pay_tips.png" alt="">
                            <span>充值套餐，享套餐权益</span>
                        </div>
                    </div>
                    <div class="pay_money">
                        <div v-for="(item,index) in rechargeList"
                             :key="index"
                             @click="checkAmount(item)"
                             :class="active===item.id ? 'pay_actived_hello_word':'pay_active_hello_word'">
                            <div class="active_title">
                                <!--                                <div class="discountLabel">1</div>-->
                                <!--                                <img src="../../assets/image/img/icon_pay_top2.png" alt="">-->
                                <!--                                <span>¥{{ item.price }} 套餐</span>-->
                                <span> {{
                                    ((item.price / item.total) * 10).toFixed(0)
                                    }}折</span>
                            </div>
                            <div class="pay2_content_money">
                                <div style="display: flex;margin-top: 5px;margin-left: 5px">
                                    <div class="money" :style="active===item.id ? {color:'#EF3A4A'} : {color: '#333'}"
                                         :class="index === 2 ? 'long_money':'money'">
                                        {{ item.total }}
                                    </div>
                                    <div style="margin-top: 20px;margin-left: 1px;font-weight: bolder">
                                        标币
                                    </div>
                                </div>
                                <div class="btn_payInfo">
                                    <div class="btn_zk">
                                        <div style="margin-right: 10px;font-size: 18px;font-weight: normal">
                                            ¥{{ item.price }}
                                        </div>
                                        <del style="font-size: 14px"> ¥{{ item.total }}.00</del>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="create_qr">
                        <div class="create_title">扫码支付</div>
                        <div class="qr_body">
                            <div v-if="timeOver" class="qrCode" style="border: 1px solid #f6f6f6">
                                <a-button @click="restart" type="primary">
                                    <a-icon type="redo"/>
                                    重新获取
                                </a-button>
                            </div>
                            <div v-else class="qrCode">
                                <a-spin :spinning="loading">
                                    <div v-if="docToPay" id="qrCodeDocToImg" ref="qrCodeFDocImg"></div>
                                    <div v-else id="qrcodeCTRValue" ref="qrCodeImg"></div>
                                </a-spin>
                            </div>
                            <div class="qr_right">
                                <div class="code_money">¥ {{ amount }}.00</div>
                                <div class="qr_info">
                                    <div style="margin-left: 10px;margin-right: 15px;font-size: 18px">
                                        <!--                                        <a-icon type="wechat" theme="filled" style="color: #2cea0e;fontSize: 25px"/>-->
                                        <img src="@/assets/image/img/weixinzhifu.svg" style="width: 20px">
                                        微信支付
                                    </div>
                                    <div style="margin-left: 10px;margin-right: 15px;font-size: 18px">
                                        <img src="@/assets/image/img/zhifubaozhifu.svg" style="width: 20px">
                                        支付宝支付
                                    </div>
                                </div>
                                <div class="qr_application">
                                    <a-icon type="info-circle"/>
                                    需申请发票，请<a href="#/user?checkId=3" target="_blank"
                                                    class="link-style">[点击这里]</a>
                                </div>
                                <div class="qr_time">
                                    <a-icon type="clock-circle"/>
                                    支付剩余时间：{{ timeSeconds }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pay2_content" v-show="chargeType===2">
                    <div class="pay_titles">
                        直接购买 : 《{{ document2.title }}》
                    </div>
                    <div class="pay_money_2">
                        <div class="pay_money_2_info1">
                            <div class="text"> 方案页数：<b>{{ document2.pages }}</b> 页</div>
                            <div class="text">文档格式：doc / docx</div>
                            <div class="text"> 需购买：<b style="color: red">{{
                                document2.price - userInfo2.fund
                                }}</b>
                                标币
                            </div>
                            <div class="recommendCharge" @click="chosePayType(1)">推荐充值套餐，价格更优惠</div>
                        </div>
                        <div class="pay_money_2_info2">
                            <div class="text"> 文档价格：<b>{{ document2.price }}</b> 标币</div>
                            <div class="text"> 当前已有：<b>{{ userInfo2.fund }}</b> 标币</div>
                            <div class="money">支付金额： <b>{{ document2.price - userInfo2.fund }}元</b></div>
                        </div>
                    </div>
                    <div class="qr_left">
                        <div class="qr_left_money">
                            支付方式：
                            <div style="margin-left: 10px;margin-right: 15px;font-size: 18px">
                                <a-icon type="wechat" theme="filled"
                                        style="color: #2cea0e;fontSize: 25px"/>
                                微信支付
                            </div>
                            <div style="margin-left: 10px;margin-right: 15px;font-size: 18px">
                                <a-icon type="alipay-circle" theme="filled"
                                        style="color: #1c7be0;fontSize: 25px"/>
                                支付宝支付
                            </div>
                            <div style="color: #da4848">扫码直接购买</div>
                        </div>
                        <div class="qr_left_QrCode">
                            <div class="qrCode_img">
                                <a-spin :spinning="loading2">
                                    <div id="qrcodeDocValue" ref="qrCodeUrlDocValue"></div>
                                </a-spin>
                            </div>
                            <div class="Qrcode_info">
                                <div style="font-size: 16px">支付金额:</div>
                                <div style="font-size: 30px;font-weight: bolder;color: red"> ¥
                                    {{ document2.price - userInfo2.fund }}.00
                                </div>
                                <div style="font-size: 16px">创建时间：<b>{{ parseTime(new Date()) }}</b></div>
                                <div class="qr_application" style="margin-top: 37px">
                                    <a-icon type="info-circle"/>
                                    需申请发票，请<a href="#/user?checkId=3" target="_blank"
                                                    class="link-style">[点击这里]</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </a-modal>

        <pay-prompt :prompt="payPromptShow" :recharge="isRecharge" @promptClose="payPromptShow=false"></pay-prompt>
        <pay-prompt :prompt="payPromptShow2" :recharge="isRecharge2" @promptClose="payPromptShow2=false"></pay-prompt>
    </div>
</template>

<script>
import './pay2.scss'
import {decrypt, encrypt} from "@/utils/AES";
import {buyBidByFund, chargeList} from "@/api/page/order";
import {debounce} from "@/utils/utils";
import QRCode from "qrcodejs2";
import phone_home from "@/pages/phone_home/phone_home";
import {createOrders} from "@/api/page/pay";
import {closeSocketWs, connectSocket, sendWSPush} from "@/utils/websocket";
import payPrompt from "@/views/page_pay/pay_prompt";
import {promoteBaiduInfo, updateOrderRecode} from "@/utils/promote";
import {addBehavior} from "@/data/accessKey";
import {mathRadomeString} from "@/utils/message";
import {getRandomCode} from "@/utils/RandomCode";
import payTips from "@/views/page_pay/pay_tips.vue";
import Pay_doc from "@/views/page_pay/pay_doc.vue";
import payDoc from "@/views/page_pay/pay_doc.vue";
import {addDownLoadLog} from "@/api/page/promoted";
import {parseTime} from "../../utils/parse";

export default {
    props: ['qrDownShow', 'payContent'],

    name: "pay",
    components: {payDoc, Pay_doc, payTips, phone_home, payPrompt},
    data() {
        return {
            active: 0,
            changeOrder: [],
            payOrder: [],
            rechargeList: [],
            amount: 0,
            time: 600,
            loading: true,
            timeClear: null,
            timeSeconds: 0,
            timeOver: false,
            payPromptShow: false,//购买成功后弹窗
            isRecharge: true, //是否为直接购买
            status: false,
            beginTime: Date.parse(new Date()),
            getSocketData: '',
            qrcode: '',
            docToPay: false,
            chargeType: 2,
            document2: {},
            userInfo: {},
            userInfo2: {},
            loading2: true,
            payPromptShow2: false,
            isRecharge2: false,
            beginTime2: Date.parse(new Date())

        }
    },
    methods: {
        parseTime,
        chosePayType(type) {
            this.chargeType = type
        },
        // ##################################### 关闭订单 #####################################
        setModal1Visible() {
            this.payOrder = []
            this.docToPay = false
            if (this.changeOrder.length > 4) {
                const memberId = JSON.parse(decrypt(localStorage.getItem('userInfo')))
                addBehavior(
                    '订单多次被点击：',
                    `用户【${memberId.phone}】多次点击订单用户的IP为【${localStorage.getItem('locationIp') ? localStorage.getItem('locationIp') : '暂无ip'}】`,
                    location.href,
                    this.beginTime,
                    Date.parse(new Date()))
            }

            // this.$message.loading({content: '当前有订单未结束...', key: 'updatable'});

            setTimeout(() => {
                // this.$message.warning({content: '遇到未到账标币尝试刷新浏览器或联系客服!', key: 'updatable', duration: 2});
                this.$emit('qrClose', true)
                addBehavior('PC订单支付：', '订单支付：' + '选择了【' + this.amount + '】选择了该金额，提前关闭弹窗，消息未推送！', location.href, this.beginTime, Date.parse(new Date()))
                window.clearInterval(this.timeClear)
                // closeSocketWs()
            }, 300);

        },


        // ##################################### 切换金额 #####################################
        checkAmount: debounce(function (item) {
            this.changeOrder.push(item)
            this.clear()
            this.amount = item.price
            this.active = item.id
            this.loading = true

            const obj = this.payOrder.find(value => value.id === item.id);
            if (obj) {
                let res = this.payOrder.filter(value => {
                    return value.id === item.id
                })
                if (this.docToPay) {
                    this.DocToPayQRCode(res[0].url)
                } else {
                    this.generateQRCode(res[0].url)
                }
            }

            if (this.payOrder.length <= 3) {
                this.createOrder({fund: item.total})
            }

            this.timeOut()


        }, 300, true),

        // ##################################### 倒计时 #####################################
        timeOut() {
            if (this.time > 0) {
                let b = 59
                let a = parseInt(this.time / 60) >= 10 ? parseInt(this.time / 60) - 1 : parseInt(this.time / 60);
                this.timeClear = setInterval(() => {
                    if (this.time > 1) {
                        this.time -= 1
                        if (b > 0) {
                            b -= 1
                        } else {
                            a -= 1
                            b = 59
                        }

                        this.timeSeconds = `0${a}:${b < 10 ? '0' + b : b}`
                    } else {
                        this.timeOver = true
                        window.clearInterval(this.timeClear)
                    }
                }, 1000)
            } else {
                this.timeOver = true
            }
        },

        // ##################################### 重新倒计时 #####################################
        restart() {
            this.time = 60
            this.timeOver = false
            this.timeOut()
        },

        // ##################################### 创建二维码 #####################################
        generateQRCode(userId) {
            // 即生成二维码之前先将存放二维码的标签清空
            // this.$refs.qrCodeUrlCtr=""

            let cabans = document.getElementById("qrcodeCTRValue")
            cabans.innerHTML = "";
            this.$refs.qrCodeImg.innerHTML = ""
            setTimeout(() => {
                this.loading = false
                this.qrcode = new QRCode(this.$refs.qrCodeImg, {
                    text: userId,
                    width: 150,
                    height: 150,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            }, 200)
        },

        DocToPayQRCode(userId) {
            // 即生成二维码之前先将存放二维码的标签清空
            // this.$refs.qrCodeUrlCtr=""
            let cabans = document.getElementById("qrCodeDocToImg")
            cabans.innerHTML = "";
            this.$refs.qrCodeFDocImg.innerHTML = ""

            setTimeout(() => {
                this.loading = false
                this.qrcode = new QRCode(this.$refs.qrCodeFDocImg, {
                    text: userId,
                    width: 150,
                    height: 150,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            }, 200)
        },
        // ##################################### 创建订单 #####################################
        createOrder(form) {
            if (this.payOrder.length > 0) {

                const obj = this.payOrder.find(item => item.id === this.active);

                if (obj) {
                } else {
                    connectSocket()

                    this.status = false

                    this.clear()

                    const memberId = JSON.parse(decrypt(localStorage.getItem('userInfo'))).id

                    let taxNum = 'BXZPC' + mathRadomeString() + Date.parse(new Date()).toString() + memberId

                    let order = {
                        type: 1, //默认1标币，2标书
                        payAmount: this.amount, //金额
                        serialNumber: taxNum,
                        note: form.fund,
                        promoteId: localStorage.getItem("promoted_id") ? localStorage.getItem("promoted_id") : '',
                        payUrl: localStorage.getItem("promoteUrl") ? localStorage.getItem("promoteUrl") : '',
                        code: getRandomCode()
                    }
                    createOrders(order).then(res => {
                        let s = encrypt(JSON.stringify({orderId: res}))
                        let url = `${process.env.VUE_APP_PAY_URL}?amount=${this.amount}&title='标币充值'&fund=${form.fund}&taxNum=${taxNum}&orderId=${res}&userId=${memberId}`
                        this.payOrder.push({id: this.active, url, orderId: s})
                        if (this.docToPay) {
                            this.DocToPayQRCode(url)
                        } else {
                            this.generateQRCode(url)
                        }
                        sendWSPush(s)
                        this.getSocketData = e => {
                            const data = e && e.detail.data
                            // 如果不是连接成功的心跳数据，弹出提示
                            if (data !== 'pong') {
                                this.handleNotifyMsg(data, e)  //处理消息的函数
                            }
                        }

                        // 注册监听事件
                        window.addEventListener('getMessage', this.getSocketData)
                        // 这里可以添加推广
                        updateOrderRecode(res, memberId)
                    }).catch(err => {
                    })
                }

            } else {

                connectSocket()

                this.status = false

                this.clear()

                const memberId = JSON.parse(decrypt(localStorage.getItem('userInfo'))).id

                let taxNum = 'BXZPC' + mathRadomeString() + Date.parse(new Date()).toString() + memberId
                let order = {
                    type: 1, //默认1标币，2标书
                    payAmount: this.amount, //金额
                    serialNumber: taxNum,
                    note: form.fund,
                    promoteId: localStorage.getItem("promoted_id") ? localStorage.getItem("promoted_id") : '',
                    payUrl: localStorage.getItem("promoteUrl") ? localStorage.getItem("promoteUrl") : '',
                    code: getRandomCode()
                }
                createOrders(order).then(res => {
                    let s = encrypt(JSON.stringify({orderId: res}))
                    let url = `${process.env.VUE_APP_PAY_URL}?amount=${this.amount}&title='标币充值'&fund=${form.fund}&taxNum=${taxNum}&orderId=${res}&userId=${memberId}`
                    this.payOrder.push({id: this.active, url})

                    if (this.docToPay) {
                        this.DocToPayQRCode(url)
                    } else {
                        this.generateQRCode(url)
                    }

                    sendWSPush(s)

                    this.getSocketData = e => {
                        const data = e && e.detail.data
                        // 如果不是连接成功的心跳数据，弹出提示
                        if (data !== 'pong') {
                            this.handleNotifyMsg(data, e)  //处理消息的函数
                        }
                    }

                    // 注册监听事件
                    window.addEventListener('getMessage', this.getSocketData)
                    // 这里可以添加推广
                    updateOrderRecode(res, memberId)
                }).catch(err => {
                })
            }
        },

        // ##################################### 返回值 #####################################
        handleNotifyMsg(data, e) {
            let reg = RegExp('success')
            if (reg.test(data)) {
                this.paySuccess()
            }

        },

        // ##################################### 支付成功 #####################################
        paySuccess() {
            this.$emit('qrClose', true)
            if (this.payPromptShow) {
            } else {
                this.payPromptShow = true;
            }
            this.$bus.$emit('chargeSuccess', true)
            promoteBaiduInfo(10)
            setTimeout(() => {
                this.promptClose()
                this.clear()
            }, 3000)
        },

        // ##################################### 提示框关闭 #####################################
        promptClose() {
            this.payPromptShow = false
        },

        // 时间初始化
        // ##################################### 时间初始化 #####################################
        clear() {
            this.time = 600
            window.clearInterval(this.timeClear)

        },
// ##################################################pay_doc内容######################################################


        // ##################################### 创建订单 #####################################
        createOrder2(form) {
            // closeSocketWs()

            connectSocket()

            const member = JSON.parse(decrypt(localStorage.getItem('userInfo')))

            let taxNum = 'BXZPC' + Date.parse(new Date()).toString() + member.id + parseInt(Math.random() * 1000)

            let order = {
                type: 2, //默认1标币，2标书
                payAmount: this.document2.price - member.fund, //金额
                serialNumber: taxNum,
                note: this.document2.price - member.fund,
                promoteId: localStorage.getItem("promoted_id") ? localStorage.getItem("promoted_id") : '',
                payUrl: localStorage.getItem("promoteUrl") ? localStorage.getItem("promoteUrl") : '',
                code: getRandomCode()
            }
            createOrders(order).then(res => {

                let s = encrypt(JSON.stringify({orderId: res}))

                let url = `${process.env.VUE_APP_PAY_URL}?amount=${this.document2.price - member.fund}&title='标币充值'&fund=${this.document2.price - member.fund}&taxNum=${taxNum}&orderId=${res}&userId=${member.id}`

                this.generateQRCode2(url);

                sessionStorage.setItem('docOrder', order.serialNumber)

                sendWSPush(s)

                this.getSocketData = e => {
                    const data = e && e.detail.data
                    // 如果不是连接成功的心跳数据，弹出提示
                    if (data !== 'pong') {
                        this.handleNotifyMsgDoc2(data, e)  //处理消息的函数
                    }
                }

                // 注册监听事件
                window.addEventListener('getMessage', this.getSocketData)
                // 这里可以添加推广
                updateOrderRecode(res, member.id)
            }).catch(err => {
            })

        },
        // ##################################### 创建二维码 #####################################
        generateQRCode2(userId) {
            // 即生成二维码之前先将存放二维码的标签清空
            document.getElementById("qrcodeDocValue").innerHTML = "";
            let qrcode = new QRCode(this.$refs.qrCodeUrlDocValue, {
                text: userId,
                width: 150,
                height: 150,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
            this.loading2 = false
        },

        // ##################################### 返回值 #####################################
        handleNotifyMsgDoc2(data, e) {
            let reg = RegExp('success')
            if (reg.test(data)) {
                this.paySuccess2()
            }
        },
        // ##################################### 支付成功 #####################################
        paySuccess2() {
            let userInfo = JSON.parse(decrypt(localStorage.getItem('userInfo')))
            let getBuy = {
                memberId: userInfo.id,
                memberPhone: userInfo.phone,
                bidId: this.document2.id,
                bidName: this.document2.title,
                amount: this.document2.price
            }

            buyBidByFund(getBuy).then(res => {
                this.$emit('qrClose', true)
                window.clearInterval(this.timeClear)
                sessionStorage.removeItem('docOrder')
                // closeSocketWs()
                this.payPromptShow2 = true
                this.$bus.$emit('PayDocSuccess', true)
                let elemIF = document.createElement("iframe");
                elemIF.src = this.document2.url;
                elemIF.style.display = "none";
                document.body.appendChild(elemIF);
                addDownLoadLog(this.document2.id).then(res => {
                })
                promoteBaiduInfo(10)
            }).catch(err => {
            })
        },
        checkUseWhichCharge(amount) {
            if (amount <= 0) {
                return this.rechargeList[0]
            } else if (amount < this.rechargeList[0].total) {
                return this.rechargeList[0]
            } else if (amount <= this.rechargeList[1].total) {
                return this.rechargeList[1];
            } else if (amount <= this.rechargeList[2].total) {
                return this.rechargeList[2];
            } else {
                return this.rechargeList[2]; // 或者根据实际需要返回的值
            }
        }
    },


    mounted() {
        chargeList().then(res => {
            this.rechargeList = res
        })
        this.$bus.$on('docToPay', data => {
            this.docToPay = true
        })

    },


    watch: {
        qrDownShow(newValue, oldValue) {
            if (newValue) {
                let userInfo = JSON.parse(decrypt(localStorage.getItem('userInfo')))
                this.userInfo2 = userInfo
                this.document2 = this.payContent
                this.createOrder2(this.document2)
            }
        },
        chargeType(newValue, oldValue) {
            if (newValue === 1) {
                let userInfo = JSON.parse(decrypt(localStorage.getItem('userInfo')))
                this.userInfo = userInfo
                this.changeOrder = []
                this.payOrder = []
                this.qrcode = ''
                this.rechargeList.forEach(((value, index) => {
                    if (index === 0) {
                        value = this.checkUseWhichCharge(this.document2.price - this.userInfo2.fund)
                        this.active = value.id
                        this.amount = value.price.toFixed(0)
                        this.loading = true
                        this.createOrder({fund: value.total})
                        this.timeOut()
                    }
                }))
            }
        }
    }
}
</script>

<style scoped>
>>> .ant-modal-content {
    border-radius: 20px;
    padding: 0;
}

>>> .ant-modal-body {
    padding: 0;
}

>>> .ant-modal-close {
    border-radius: 0 10px 0 0;
    background-color: white;
}
</style>
